<template>
  <div class="add-new-location">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('location.create')"
      :title="$t('location.new')"
      @submitPressed="submitPressed"
    />
    <location-form :locationObject="location" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import LocationForm from './components/LocationForm.vue'

export default {
  components: {
    TitleBar,
    LocationForm
  },
  data() {
    return {
      action: null,
      location: {
        airport: '',
        country: '',
        region: '',
        city: '',
        locality: '',
        postalCode: '',
        mapLatitude: '',
        mapLongitude: '',
      },
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
}
</script>
